import * as ExpoFS from "expo-file-system";
import { FileSystem } from "./FileSystem";

export class ExpoFileSystem implements FileSystem {
  private baseDir: string;

  constructor() {
    this.baseDir = ExpoFS.documentDirectory || "";
    this.initializeDirectories();
  }

  private async initializeDirectories() {
    const dirs = ["/vision", "/goals", "/tasks"];
    for (const dir of dirs) {
      const path = this.getFullPath(dir);
      const exists = await this.exists(path);
      if (!exists) {
        await this.mkdir(path);
      }
    }
  }

  private getFullPath(path: string): string {
    return this.baseDir + path;
  }

  async readFile(path: string): Promise<string> {
    try {
      return await ExpoFS.readAsStringAsync(this.getFullPath(path));
    } catch (error) {
      if ((error as any).code === "ENOENT") {
        throw new Error(`File not found: ${path}`);
      }
      throw error;
    }
  }

  async readBinaryFile(path: string): Promise<ArrayBuffer> {
    try {
      const result = await ExpoFS.readAsStringAsync(this.getFullPath(path), {
        encoding: ExpoFS.EncodingType.Base64,
      });
      const binaryString = atob(result);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes.buffer;
    } catch (error) {
      if ((error as any).code === "ENOENT") {
        throw new Error(`File not found: ${path}`);
      }
      throw error;
    }
  }

  async writeFile(path: string, content: string): Promise<void> {
    await ExpoFS.writeAsStringAsync(this.getFullPath(path), content);
  }

  async writeBinaryFile(path: string, content: ArrayBuffer): Promise<void> {
    const bytes = new Uint8Array(content);
    let binary = "";
    for (let i = 0; i < bytes.length; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    const base64 = btoa(binary);
    await ExpoFS.writeAsStringAsync(this.getFullPath(path), base64, {
      encoding: ExpoFS.EncodingType.Base64,
    });
  }

  async exists(path: string): Promise<boolean> {
    try {
      const info = await ExpoFS.getInfoAsync(this.getFullPath(path));
      return info.exists;
    } catch {
      return false;
    }
  }

  async mkdir(path: string): Promise<void> {
    await ExpoFS.makeDirectoryAsync(this.getFullPath(path), {
      intermediates: true,
    });
  }

  async readDir(path: string): Promise<string[]> {
    return await ExpoFS.readDirectoryAsync(this.getFullPath(path));
  }
}
