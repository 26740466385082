import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  FlatList,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
  Platform,
} from "react-native";
import { Goal } from "../types";
import { getFileSystem, STORAGE_PATHS } from "../services/storage";
import { eventEmitter, SYNC_COMPLETED } from "../services/events";

export default function GoalsScreen() {
  const [goals, setGoals] = useState<Goal[]>([]);
  const [newGoal, setNewGoal] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    loadGoals();
    const subscription = addListener(SYNC_COMPLETED, loadGoals);
    return () => subscription.remove();
  }, []);

  const loadGoals = async () => {
    try {
      const fs = getFileSystem();
      const goalsPath = STORAGE_PATHS.goals;
      if (await fs.exists(goalsPath)) {
        const content = await fs.readFile(goalsPath);
        setGoals(JSON.parse(content));
      } else {
        setGoals([]);
      }
    } catch (error) {
      console.error("Error loading goals:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveGoals = async (updatedGoals: Goal[]) => {
    try {
      setIsSaving(true);
      const fs = getFileSystem();
      await fs.writeFile(STORAGE_PATHS.goals, JSON.stringify(updatedGoals));
      setGoals(updatedGoals);
    } catch (error) {
      console.error("Error saving goals:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const addGoal = () => {
    if (!newGoal.trim()) return;

    const goal: Goal = {
      id: Date.now().toString(),
      text: newGoal.trim(),
      completed: false,
    };

    saveGoals([...goals, goal]);
    setNewGoal("");
  };

  const toggleGoal = (id: string) => {
    const updatedGoals = goals.map((goal) =>
      goal.id === id ? { ...goal, completed: !goal.completed } : goal
    );
    saveGoals(updatedGoals);
  };

  const renderItem = ({ item }: { item: Goal }) => (
    <TouchableOpacity
      style={[
        styles.goalItem,
        { backgroundColor: item.completed ? "#2d2d2d" : "#1f1f1f" },
      ]}
      onPress={() => toggleGoal(item.id)}
    >
      <Text
        style={[
          styles.goalText,
          { textDecorationLine: item.completed ? "line-through" : "none" },
        ]}
      >
        {item.text}
      </Text>
    </TouchableOpacity>
  );

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <Text style={styles.loadingText}>Loading...</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.inputContainer}>
        <TextInput
          style={styles.input}
          value={newGoal}
          onChangeText={setNewGoal}
          placeholder="Add a new goal..."
          placeholderTextColor="#666"
          onSubmitEditing={addGoal}
        />
      </View>
      {isSaving && <Text style={styles.savingText}>Saving changes...</Text>}
      <FlatList
        data={goals}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        style={styles.list}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#1a1a1a",
  },
  loadingContainer: {
    flex: 1,
    backgroundColor: "#1a1a1a",
    justifyContent: "center",
    alignItems: "center",
  },
  inputContainer: {
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: "#333",
  },
  input: {
    backgroundColor: "#2d2d2d",
    padding: 15,
    borderRadius: 5,
    color: "#fff",
  },
  list: {
    flex: 1,
  },
  goalItem: {
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: "#333",
  },
  goalText: {
    color: "#fff",
    fontSize: 16,
  },
  savingText: {
    color: "#666",
    textAlign: "center",
    padding: 10,
  },
  loadingText: {
    color: "#fff",
    fontSize: 16,
  },
});
