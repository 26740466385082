import { EventEmitter } from "events";

export const SYNC_COMPLETED = "sync_completed";

export const eventEmitter = new EventEmitter();

// Add type-safe event listener methods
export const addListener = (
  event: string,
  listener: (...args: any[]) => void
) => {
  eventEmitter.on(event, listener);
  return {
    remove: () => eventEmitter.off(event, listener),
  };
};
