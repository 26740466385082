import React, { useState } from "react";
import { Platform, View, TouchableOpacity, Text } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import LoginScreen from "./src/screens/LoginScreen";
import VisionScreen from "./src/screens/VisionScreen";
import GoalsScreen from "./src/screens/GoalsScreen";
import TasksScreen from "./src/screens/TasksScreen";
import { getGitSync } from "./src/services/GitSync";
import { ConflictDialog } from "./src/components/ConflictDialog";
import type { ConflictData } from "./src/components/ConflictDialog";

// Increase max listeners
require("events").EventEmitter.defaultMaxListeners = 32;

type Screen = "Login" | "Vision" | "Goals" | "Tasks";

function AppContainer({ children }: { children: React.ReactNode }) {
  if (Platform.OS === "web") {
    return (
      <View style={{ flex: 1, backgroundColor: "#000", alignItems: "center" }}>
        <View
          style={{
            flex: 1,
            width: "100%",
            maxWidth: 600,
            backgroundColor: "#1a1a1a",
          }}
        >
          {children}
        </View>
      </View>
    );
  }
  return <>{children}</>;
}

export default function App() {
  const [currentScreen, setCurrentScreen] = useState<Screen>("Login");
  const [isSyncing, setIsSyncing] = useState(false);
  const [currentConflict, setCurrentConflict] = useState<ConflictData | null>(
    null
  );

  const handleSync = async () => {
    try {
      setIsSyncing(true);
      const gitSync = getGitSync();

      const result = await gitSync.sync(async (conflict) => {
        return new Promise<boolean>((resolve) => {
          const conflictWithHandlers: ConflictData = {
            ...conflict,
            onResolve: (useLocal: boolean) => {
              setCurrentConflict(null);
              resolve(useLocal);
            },
            onCancel: () => {
              setCurrentConflict(null);
              resolve(false);
            },
          };
          setCurrentConflict(conflictWithHandlers);
        });
      });

      if (!result.success) {
        console.error("Sync failed:", result.error);
      }
    } catch (error) {
      console.error("Sync error:", error);
    } finally {
      setIsSyncing(false);
    }
  };

  const Header = () => {
    if (currentScreen === "Login") return null;

    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 15,
          backgroundColor: "#1a1a1a",
          borderBottomWidth: 1,
          borderBottomColor: "#333",
        }}
      >
        <View style={{ flexDirection: "row", gap: 15 }}>
          <TouchableOpacity onPress={() => setCurrentScreen("Vision")}>
            <Text
              style={{ color: currentScreen === "Vision" ? "#2ea44f" : "#666" }}
            >
              Vision
            </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setCurrentScreen("Goals")}>
            <Text
              style={{ color: currentScreen === "Goals" ? "#2ea44f" : "#666" }}
            >
              Goals
            </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setCurrentScreen("Tasks")}>
            <Text
              style={{ color: currentScreen === "Tasks" ? "#2ea44f" : "#666" }}
            >
              Tasks
            </Text>
          </TouchableOpacity>
        </View>
        <TouchableOpacity onPress={handleSync} disabled={isSyncing}>
          {isSyncing ? (
            <Text style={{ color: "#2ea44f", fontSize: 14 }}>Syncing...</Text>
          ) : (
            <Ionicons name="git-branch-outline" size={24} color="#2ea44f" />
          )}
        </TouchableOpacity>
      </View>
    );
  };

  const renderScreen = () => {
    switch (currentScreen) {
      case "Login":
        return <LoginScreen onLogin={() => setCurrentScreen("Vision")} />;
      case "Vision":
        return <VisionScreen />;
      case "Goals":
        return <GoalsScreen />;
      case "Tasks":
        return <TasksScreen />;
    }
  };

  return (
    <AppContainer>
      <View style={{ flex: 1, backgroundColor: "#1a1a1a" }}>
        <Header />
        {renderScreen()}
        {currentConflict && (
          <ConflictDialog
            conflict={currentConflict}
            onResolve={(useLocal) => currentConflict.onResolve?.(useLocal)}
            onCancel={() => currentConflict.onCancel?.()}
            visible={true}
          />
        )}
      </View>
    </AppContainer>
  );
}
