import { FileSystem } from "./FileSystem";
import { ExpoFileSystem } from "./ExpoFileSystem";
import { WebFileSystem } from "./WebFileSystem";

export const STORAGE_PATHS = {
  vision: "vision.json",
  visionImage: "vision.png",
  goals: "goals.json",
  tasks: "tasks.json",
} as const;

export function getFileSystem(): FileSystem {
  if (typeof window !== "undefined") {
    return new WebFileSystem();
  } else {
    return new ExpoFileSystem();
  }
}

export * from "./FileSystem";
