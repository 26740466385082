import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Image,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Platform,
} from "react-native";
import { Vision } from "../types";
import { getFileSystem, STORAGE_PATHS } from "../services/storage";
import { eventEmitter, SYNC_COMPLETED } from "../services/events";
import { Ionicons } from "@expo/vector-icons";

const DEFAULT_VISION: Vision = {
  description: "",
  imageUrl:
    "https://images.unsplash.com/photo-1616036740257-9449ea1f6605?w=800&auto=format&fit=crop&q=80",
};

export default function VisionScreen() {
  const [vision, setVision] = useState<Vision>(DEFAULT_VISION);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    loadVision();
    loadImage();

    // Listen for sync completed events
    const onSyncCompleted = () => {
      console.log("Sync completed, reloading vision data");
      loadVision();
      loadImage();
    };

    eventEmitter.on(SYNC_COMPLETED, onSyncCompleted);

    // Cleanup listener on unmount
    return () => {
      eventEmitter.off(SYNC_COMPLETED, onSyncCompleted);
    };
  }, []);

  const loadVision = async () => {
    try {
      setIsLoading(true);
      const fs = getFileSystem();

      // Load vision data
      if (await fs.exists(STORAGE_PATHS.vision)) {
        console.log("Loading vision data...");
        const content = await fs.readFile(STORAGE_PATHS.vision);
        const visionData = JSON.parse(content);
        console.log("Vision data loaded:", visionData);

        // Revoke old blob URL if it exists
        if (visionData.imageUrl?.startsWith("blob:")) {
          console.log("Revoking old blob URL:", visionData.imageUrl);
          URL.revokeObjectURL(visionData.imageUrl);
        }
        setVision(visionData);
      }

      // Load vision image
      if (await fs.exists(STORAGE_PATHS.visionImage)) {
        console.log("Loading vision image...");
        const imageBuffer = await fs.readBinaryFile(STORAGE_PATHS.visionImage);
        console.log("Image buffer loaded, size:", imageBuffer.byteLength);
        const blob = new Blob([imageBuffer], { type: "image/png" });
        const imageUrl = URL.createObjectURL(blob);
        console.log("Created new blob URL:", imageUrl);

        // Update vision state with new image URL
        setVision((prev) => {
          // Save the updated vision data back to storage to persist the new blob URL
          const updatedVision = { ...prev, imageUrl };
          fs.writeFile(
            STORAGE_PATHS.vision,
            JSON.stringify(updatedVision, null, 2)
          ).catch((err) =>
            console.error("Error saving updated vision data:", err)
          );
          return updatedVision;
        });
      } else {
        console.log("No vision image found in storage");
      }
    } catch (error) {
      console.error("Error loading vision:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveVision = async (updatedVision: Vision) => {
    try {
      setIsSaving(true);
      const fs = getFileSystem();
      const visionPath = STORAGE_PATHS.vision;
      await fs.writeFile(visionPath, JSON.stringify(updatedVision, null, 2));
      setVision(updatedVision);
    } catch (error) {
      console.error("Error saving vision:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDescriptionChange = (text: string) => {
    const updatedVision = { ...vision, description: text };
    setVision(updatedVision);
    saveVision(updatedVision);
  };

  const handleImageChange = async (imageUri: string) => {
    try {
      setIsSaving(true);

      // Save vision data
      const updatedVision = { ...vision, imageUrl: imageUri };
      await saveVision(updatedVision);

      // Download and save image
      const response = await fetch(imageUri);
      const blob = await response.blob();

      // Convert to PNG if it's not already
      const canvas = document.createElement("canvas");
      const img = document.createElement("img");
      await new Promise((resolve) => {
        img.onload = resolve;
        img.src = imageUri;
      });
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      if (!ctx) throw new Error("Could not get canvas context");
      ctx.drawImage(img, 0, 0);
      const pngBlob = await new Promise<Blob>((resolve) =>
        canvas.toBlob((blob) => resolve(blob!), "image/png")
      );

      const arrayBuffer = await pngBlob.arrayBuffer();
      const fs = getFileSystem();
      await fs.writeBinaryFile(STORAGE_PATHS.visionImage, arrayBuffer);

      setVision(updatedVision);
    } catch (error) {
      console.error("Error saving vision image:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const loadImage = async () => {
    try {
      const fs = getFileSystem();
      if (await fs.exists(STORAGE_PATHS.visionImage)) {
        const arrayBuffer = await fs.readBinaryFile(STORAGE_PATHS.visionImage);
        const blob = new Blob([arrayBuffer], { type: "image/png" });
        const imageUrl = URL.createObjectURL(blob);
        setVision((prev) => ({ ...prev, imageUrl }));
      }
    } catch (error) {
      console.error("Error loading vision image:", error);
    }
  };

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <Text style={styles.loadingText}>Loading...</Text>
      </View>
    );
  }

  return (
    <ScrollView style={styles.container}>
      <View style={styles.content}>
        <View style={styles.imageContainer}>
          <Image
            source={{ uri: vision.imageUrl }}
            style={styles.image}
            resizeMode="cover"
          />
          <TouchableOpacity
            style={styles.editButton}
            onPress={() =>
              document.getElementById("vision-image-input")?.click()
            }
          >
            <Ionicons name="pencil-outline" size={20} color="#fff" />
          </TouchableOpacity>
        </View>

        <input
          id="vision-image-input"
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) {
              const imageUrl = URL.createObjectURL(file);
              handleImageChange(imageUrl);
            }
          }}
        />

        <Text style={styles.label}>Your Vision</Text>
        <TextInput
          style={styles.input}
          multiline
          numberOfLines={6}
          value={vision.description}
          onChangeText={handleDescriptionChange}
          placeholder="Describe your vision..."
          placeholderTextColor="#666"
        />

        {isSaving && <Text style={styles.savingText}>Saving...</Text>}
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#1a1a1a",
  },
  content: {
    padding: 20,
  },
  loadingContainer: {
    flex: 1,
    backgroundColor: "#1a1a1a",
    justifyContent: "center",
    alignItems: "center",
  },
  imageContainer: {
    position: "relative",
    marginBottom: 20,
  },
  image: {
    width: "100%",
    height: 200,
    borderRadius: 10,
  },
  editButton: {
    position: "absolute",
    right: 10,
    bottom: 10,
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
  },
  input: {
    backgroundColor: "#333",
    color: "#fff",
    borderRadius: 6,
    padding: 12,
    fontSize: 16,
    minHeight: 120,
    textAlignVertical: "top",
  },
  buttonText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
  savingText: {
    color: "#666",
    fontSize: 14,
    textAlign: "center",
    marginTop: 10,
  },
  loadingText: {
    color: "#fff",
    fontSize: 16,
  },
});
