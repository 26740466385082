import { FileSystem } from "./FileSystem";
import { STORAGE_PATHS } from "./index";

export class WebFileSystem implements FileSystem {
  private db: IDBDatabase | null = null;
  private dbName = "todo.ac";
  private storeName = "files";

  constructor() {
    this.initDB().then(() => this.createTestData());
  }

  private async createTestData() {
    // Create empty data structures if no files exist
    const hasVision = await this.exists(STORAGE_PATHS.vision);
    const hasGoals = await this.exists(STORAGE_PATHS.goals);
    const hasTasks = await this.exists(STORAGE_PATHS.tasks);

    if (!hasVision) {
      console.log("Initializing vision data");
      await this.writeFile(
        STORAGE_PATHS.vision,
        JSON.stringify({
          description: "",
          imageUrl:
            "https://placehold.co/600x400/1a1a1a/white?text=Your+Vision",
        })
      );
    }

    if (!hasGoals) {
      console.log("Initializing goals data");
      await this.writeFile(STORAGE_PATHS.goals, JSON.stringify([]));
    }

    if (!hasTasks) {
      console.log("Initializing tasks data");
      await this.writeFile(STORAGE_PATHS.tasks, JSON.stringify([]));
    }
  }

  private async initDB(): Promise<void> {
    if (this.db) return;

    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, 1);

      request.onerror = () => reject(request.error);

      request.onsuccess = () => {
        this.db = request.result;
        resolve();
      };

      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        if (!db.objectStoreNames.contains(this.storeName)) {
          db.createObjectStore(this.storeName);
        }
      };
    });
  }

  async exists(path: string): Promise<boolean> {
    await this.initDB();
    return new Promise((resolve, reject) => {
      if (!this.db) {
        reject(new Error("Database not initialized"));
        return;
      }

      const transaction = this.db.transaction([this.storeName], "readonly");
      const store = transaction.objectStore(this.storeName);
      const request = store.get(path);

      request.onsuccess = () => {
        resolve(request.result !== undefined);
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }

  async readFile(path: string): Promise<string> {
    await this.initDB();
    return new Promise((resolve, reject) => {
      if (!this.db) {
        reject(new Error("Database not initialized"));
        return;
      }

      const transaction = this.db.transaction([this.storeName], "readonly");
      const store = transaction.objectStore(this.storeName);
      const request = store.get(path);

      request.onsuccess = () => {
        if (request.result === undefined) {
          reject(new Error("File not found"));
        } else {
          resolve(request.result);
        }
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }

  async readBinaryFile(path: string): Promise<ArrayBuffer> {
    await this.initDB();
    return new Promise((resolve, reject) => {
      if (!this.db) {
        reject(new Error("Database not initialized"));
        return;
      }

      const transaction = this.db.transaction([this.storeName], "readonly");
      const store = transaction.objectStore(this.storeName);
      const request = store.get(path);

      request.onsuccess = () => {
        if (request.result === undefined) {
          reject(new Error("File not found"));
        } else {
          resolve(request.result);
        }
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }

  async writeFile(path: string, content: string): Promise<void> {
    await this.initDB();
    return new Promise((resolve, reject) => {
      if (!this.db) {
        reject(new Error("Database not initialized"));
        return;
      }

      const transaction = this.db.transaction([this.storeName], "readwrite");
      const store = transaction.objectStore(this.storeName);
      const request = store.put(content, path);

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }

  async writeBinaryFile(path: string, content: ArrayBuffer): Promise<void> {
    await this.initDB();
    return new Promise((resolve, reject) => {
      if (!this.db) {
        reject(new Error("Database not initialized"));
        return;
      }

      const transaction = this.db.transaction([this.storeName], "readwrite");
      const store = transaction.objectStore(this.storeName);
      const request = store.put(content, path);

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }

  async deleteFile(path: string): Promise<void> {
    await this.initDB();
    return new Promise((resolve, reject) => {
      if (!this.db) {
        reject(new Error("Database not initialized"));
        return;
      }

      const transaction = this.db.transaction([this.storeName], "readwrite");
      const store = transaction.objectStore(this.storeName);
      const request = store.delete(path);

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }

  async mkdir(path: string): Promise<void> {
    // No-op for IndexedDB since we don't need actual directories
    // Files are stored with full paths as keys
    console.log(`mkdir called for path: ${path} (no-op in IndexedDB)`);
  }

  async readDir(path: string): Promise<string[]> {
    await this.initDB();
    return new Promise((resolve, reject) => {
      if (!this.db) {
        reject(new Error("Database not initialized"));
        return;
      }

      const transaction = this.db.transaction([this.storeName], "readonly");
      const store = transaction.objectStore(this.storeName);
      const request = store.getAllKeys();

      request.onsuccess = () => {
        const allKeys = request.result as string[];
        // Filter keys that start with the given path
        const filesInDir = allKeys.filter((key) =>
          key.startsWith(path === "/" ? "" : path)
        );
        resolve(filesInDir);
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }
}
