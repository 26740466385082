import { getFileSystem, STORAGE_PATHS } from "./storage";
import { Vision, Goal, Todo } from "../types";
import { eventEmitter, SYNC_COMPLETED } from "./events";
import { Octokit } from "@octokit/rest";

export interface ConflictData {
  path: string;
  local: {
    content: any;
    timestamp: string;
  };
  remote: {
    content: any;
    timestamp: string;
  };
}

export class GitSync {
  private fs: any;
  private baseUrl: string;
  private octokit: Octokit;

  constructor(
    private token: string,
    private username: string,
    private repoName: string
  ) {
    this.fs = getFileSystem();
    this.baseUrl = `https://api.github.com/repos/${username}/${repoName}/contents`;
    this.octokit = new Octokit({
      auth: token,
      baseUrl: "https://api.github.com",
    });
  }

  async sync(onConflict?: (conflict: ConflictData) => Promise<boolean>) {
    try {
      console.log("Starting sync process...");

      // Ensure repository exists
      console.log("Checking if repository exists...");
      await this.ensureRepoExists();
      console.log("Repository check complete");

      // Get remote files
      console.log("Fetching remote files...");
      const [remoteVision, remoteGoals, remoteTasks, remoteVisionImage] =
        await Promise.all([
          this.getRemoteFile("vision.json"),
          this.getRemoteFile("goals.json"),
          this.getRemoteFile("tasks.json"),
          this.getRemoteFile("vision.png", true),
        ]);
      console.log("Remote files status:", {
        vision: remoteVision ? "exists" : "not found",
        goals: remoteGoals ? "exists" : "not found",
        tasks: remoteTasks ? "exists" : "not found",
        visionImage: remoteVisionImage ? "exists" : "not found",
      });

      // Get local files
      console.log("Reading local files...");
      const [localVision, localGoals, localTasks, localVisionImage] =
        await Promise.all([
          this.getLocalFile(STORAGE_PATHS.vision),
          this.getLocalFile(STORAGE_PATHS.goals),
          this.getLocalFile(STORAGE_PATHS.tasks),
          this.getLocalBinaryFile(STORAGE_PATHS.visionImage),
        ]);
      console.log("Local files status:", {
        vision: localVision ? "exists" : "not found",
        goals: localGoals ? "exists" : "not found",
        tasks: localTasks ? "exists" : "not found",
        visionImage: localVisionImage ? "exists" : "not found",
      });

      // Check for conflicts
      console.log("Checking for conflicts...");
      const conflicts: ConflictData[] = [];
      if (
        remoteVision &&
        localVision &&
        this.hasConflict(localVision, remoteVision.content)
      ) {
        console.log("Found conflict in vision");
        conflicts.push({
          path: "vision",
          local: { content: localVision, timestamp: new Date().toISOString() },
          remote: {
            content: remoteVision.content,
            timestamp: remoteVision.timestamp,
          },
        });
      }

      if (
        remoteGoals &&
        localGoals &&
        this.hasConflict(localGoals, remoteGoals.content)
      ) {
        console.log("Found conflict in goals");
        conflicts.push({
          path: "goals",
          local: { content: localGoals, timestamp: new Date().toISOString() },
          remote: {
            content: remoteGoals.content,
            timestamp: remoteGoals.timestamp,
          },
        });
      }

      if (
        remoteTasks &&
        localTasks &&
        this.hasConflict(localTasks, remoteTasks.content)
      ) {
        console.log("Found conflict in tasks");
        conflicts.push({
          path: "tasks",
          local: { content: localTasks, timestamp: new Date().toISOString() },
          remote: {
            content: remoteTasks.content,
            timestamp: remoteTasks.timestamp,
          },
        });
      }
      console.log(`Found ${conflicts.length} conflicts`);

      // Handle conflicts or create/update files
      if (conflicts.length > 0 && onConflict) {
        console.log("Resolving conflicts...");
        // Handle conflicts
        for (const conflict of conflicts) {
          console.log(`Resolving conflict for ${conflict.path}...`);
          const useLocal = await onConflict(conflict);
          if (useLocal === undefined) {
            console.log("Conflict resolution cancelled by user");
            return { success: false, error: "Sync cancelled" };
          }
          console.log(
            `Using ${useLocal ? "local" : "remote"} version for ${
              conflict.path
            }`
          );
          const content = useLocal
            ? conflict.local.content
            : conflict.remote.content;

          // Update remote file
          await this.updateRemoteFile(
            `${conflict.path}.json`,
            content,
            useLocal ? undefined : remoteVision?.sha
          );

          // If this is a vision conflict and we're using local version, also update the image
          if (conflict.path === "vision" && useLocal && localVisionImage) {
            console.log("Updating vision image with local version...");
            await this.updateRemoteFile(
              "vision.png",
              localVisionImage,
              remoteVisionImage?.sha
            );
            console.log("Vision image update completed");
          }

          // If using remote version, update local storage
          if (!useLocal) {
            console.log(
              `Updating local storage with remote data for ${conflict.path}`
            );
            await this.fs.writeFile(
              STORAGE_PATHS[conflict.path as keyof typeof STORAGE_PATHS],
              JSON.stringify(content, null, 2)
            );

            // If this was a vision conflict and we have a remote image, save it too
            if (conflict.path === "vision" && remoteVisionImage) {
              console.log(
                "Saving remote vision image during conflict resolution"
              );
              const imageBuffer = this.base64ToArrayBuffer(
                remoteVisionImage.content
              );
              await this.fs.writeBinaryFile(
                STORAGE_PATHS.visionImage,
                imageBuffer
              );
            }
          }

          console.log(`Conflict resolved for ${conflict.path}`);
        }
      } else {
        console.log("No conflicts found, updating remote files...");
        // Create or update files
        const updates: Promise<void>[] = [];

        // If local is empty but remote exists, use remote data
        if (!localVision && remoteVision) {
          console.log("Using remote vision data");
          await this.fs.writeFile(
            STORAGE_PATHS.vision,
            JSON.stringify(remoteVision.content, null, 2)
          );
        }

        // Always save remote vision image if it exists
        if (remoteVisionImage) {
          console.log("Saving remote vision image to local storage");
          const imageBuffer = this.base64ToArrayBuffer(
            remoteVisionImage.content
          );
          await this.fs.writeBinaryFile(STORAGE_PATHS.visionImage, imageBuffer);
        }

        if (!localGoals && remoteGoals) {
          console.log("Using remote goals data");
          await this.fs.writeFile(
            STORAGE_PATHS.goals,
            JSON.stringify(remoteGoals.content, null, 2)
          );
        }

        if (!localTasks && remoteTasks) {
          console.log("Using remote tasks data");
          await this.fs.writeFile(
            STORAGE_PATHS.tasks,
            JSON.stringify(remoteTasks.content, null, 2)
          );
        }

        // Update remote files if local data exists
        if (localVision || remoteVision) {
          console.log("Preparing vision update...");
          updates.push(
            this.updateRemoteFile(
              "vision.json",
              localVision || (remoteVision?.content ?? {}),
              remoteVision?.sha
            )
          );
        }

        // Always update vision image if we have a local one
        if (localVisionImage) {
          console.log("Preparing vision image update...");
          updates.push(
            this.updateRemoteFile(
              "vision.png",
              localVisionImage,
              remoteVisionImage?.sha
            )
          );
        }

        if (localGoals || remoteGoals) {
          console.log("Preparing goals update...");
          updates.push(
            this.updateRemoteFile(
              "goals.json",
              localGoals || (remoteGoals?.content ?? []),
              remoteGoals?.sha
            )
          );
        }

        if (localTasks || remoteTasks) {
          console.log("Preparing tasks update...");
          updates.push(
            this.updateRemoteFile(
              "tasks.json",
              localTasks || (remoteTasks?.content ?? []),
              remoteTasks?.sha
            )
          );
        }

        // Wait for all updates to complete
        console.log(`Waiting for ${updates.length} updates to complete...`);
        try {
          await Promise.all(updates);
          console.log("All updates completed successfully");
        } catch (error) {
          console.error("Error during updates:", error);
          throw error;
        }
      }

      console.log("Sync completed successfully");
      eventEmitter.emit(SYNC_COMPLETED);
      return { success: true };
    } catch (error) {
      console.error("Sync error:", error);
      return { success: false, error };
    }
  }

  private async ensureRepoExists() {
    try {
      console.log(`Checking repository ${this.username}/${this.repoName}...`);

      try {
        // Check if repo exists
        await this.octokit.repos.get({
          owner: this.username,
          repo: this.repoName,
        });
        console.log("Repository exists");
      } catch (error: any) {
        if (error.status === 404) {
          console.log("Repository not found, creating it...");

          try {
            // Create repository in user account
            await this.octokit.repos.createForAuthenticatedUser({
              name: this.repoName,
              private: true,
              auto_init: true,
              description: "Personal todo data repository",
            });

            console.log("Repository created successfully in user account");
            await new Promise((resolve) => setTimeout(resolve, 1000));
          } catch (userError: any) {
            console.error("Failed to create repository:", {
              status: userError.status,
              message: userError.message,
            });

            // Try to get more information about the token
            try {
              const { data: user } =
                await this.octokit.users.getAuthenticated();
              console.log("Authenticated user:", user);
            } catch (authError) {
              console.error("Error checking authentication:", authError);
            }

            throw new Error(
              `Failed to create repository: ${userError.message}`
            );
          }
        }
      }
    } catch (error) {
      console.error("Error ensuring repo exists:", error);
      throw error;
    }
  }

  private async getRemoteFile(path: string, isBinary: boolean = false) {
    try {
      console.log(`Fetching remote file: ${path}`);
      const response = await fetch(`${this.baseUrl}/${path}`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/vnd.github.v3+json",
        },
      });

      if (response.status === 404) {
        console.log(`Remote file not found: ${path}`);
        return null;
      }

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error(`GitHub API error for ${path}:`, {
          status: response.status,
          statusText: response.statusText,
          data: errorData,
        });
        throw new Error(`GitHub API error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log(`Successfully fetched remote file: ${path}`);
      return {
        content: isBinary ? data.content : JSON.parse(atob(data.content)),
        sha: data.sha,
        timestamp: new Date(
          data.commit?.committer?.date || Date.now()
        ).toISOString(),
      };
    } catch (error) {
      if (error instanceof Error && error.message.includes("404")) {
        console.log(`Remote file not found: ${path}`);
        return null;
      }
      console.error(`Error getting remote file ${path}:`, error);
      return null;
    }
  }

  private async getLocalFile(path: string) {
    try {
      console.log(`Reading local file: ${path}`);
      if (await this.fs.exists(path)) {
        const content = await this.fs.readFile(path);
        console.log(`Successfully read local file: ${path}`);
        return JSON.parse(content);
      }
      console.log(`Local file not found: ${path}`);
      return null;
    } catch (error) {
      console.error(`Error getting local file ${path}:`, error);
      return null;
    }
  }

  private async getLocalBinaryFile(path: string) {
    try {
      console.log(`Reading local binary file: ${path}`);
      if (await this.fs.exists(path)) {
        const content = await this.fs.readBinaryFile(path);
        console.log(`Successfully read local binary file: ${path}`);
        return content;
      }
      console.log(`Local binary file not found: ${path}`);
      return null;
    } catch (error) {
      console.error(`Error getting local binary file ${path}:`, error);
      return null;
    }
  }

  private async updateRemoteFile(path: string, content: any, sha?: string) {
    const maxRetries = 3;
    let retryCount = 0;

    while (retryCount < maxRetries) {
      try {
        console.log(
          `Updating remote file: ${path}${
            sha ? " (with SHA)" : " (new file)"
          } - Attempt ${retryCount + 1}`
        );

        // Always get the latest SHA before updating
        const response = await fetch(`${this.baseUrl}/${path}`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
            Accept: "application/vnd.github.v3+json",
          },
        });

        // If file exists, get its SHA
        if (response.ok) {
          const data = await response.json();
          sha = data.sha;
        }

        // Convert content to base64 if it's a binary file
        let base64Content;
        if (content instanceof ArrayBuffer) {
          const bytes = new Uint8Array(content);
          let binary = "";
          for (let i = 0; i < bytes.length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          base64Content = btoa(binary);
        } else {
          base64Content = btoa(JSON.stringify(content, null, 2));
        }

        console.log(`Making PUT request to ${this.baseUrl}/${path}`);
        const updateResponse = await fetch(`${this.baseUrl}/${path}`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${this.token}`,
            Accept: "application/vnd.github.v3+json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            message: `Update ${path}`,
            content: base64Content,
            sha,
          }),
        });

        if (updateResponse.ok) {
          console.log(`Successfully updated remote file: ${path}`);
          return;
        }

        const errorData = await updateResponse.json().catch(() => ({}));

        // If it's a conflict and we haven't exceeded retries, try again
        if (updateResponse.status === 409 && retryCount < maxRetries - 1) {
          console.log(
            `SHA conflict detected, retrying... (${
              retryCount + 1
            }/${maxRetries})`
          );
          retryCount++;
          continue;
        }

        console.error(`GitHub API error for ${path}:`, {
          status: updateResponse.status,
          statusText: updateResponse.statusText,
          data: errorData,
        });
        throw new Error(`GitHub API error: ${updateResponse.statusText}`);
      } catch (error) {
        console.error(`Error updating remote file ${path}:`, error);
        throw error;
      }
    }
  }

  private hasConflict(local: any, remote: any): boolean {
    const result = JSON.stringify(local) !== JSON.stringify(remote);
    if (result) {
      console.log("Conflict detected:", {
        local,
        remote,
      });
    }
    return result;
  }

  private base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }
}

// Singleton instance
let gitSync: GitSync | null = null;

export function initGitSync(token: string, username: string, repoName: string) {
  gitSync = new GitSync(token, username, repoName);
  return gitSync;
}

export function getGitSync() {
  if (!gitSync) {
    throw new Error("GitSync not initialized");
  }
  return gitSync;
}
