import { Octokit } from "@octokit/rest";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as WebBrowser from "expo-web-browser";
import { makeRedirectUri } from "expo-auth-session";
import { Platform } from "react-native";
import Constants from "expo-constants";
import { initGitSync } from "./GitSync";

class GitHubAppService {
  private octokit: Octokit | null = null;
  private installationId: number | null = null;

  async initialize() {
    // First, try to get the installation ID from storage
    const storedInstallationId = await AsyncStorage.getItem("installationId");
    if (storedInstallationId) {
      this.installationId = parseInt(storedInstallationId, 10);
      return this.refreshToken();
    }

    // If no stored installation, start the OAuth flow
    return this.startOAuthFlow();
  }

  private async startOAuthFlow() {
    console.log("Starting OAuth flow...");
    const appId = process.env.EXPO_PUBLIC_GITHUB_APP_ID;
    const clientId = process.env.EXPO_PUBLIC_GITHUB_APP_CLIENT_ID;

    console.log("Checking app credentials:", {
      appId,
      clientId: clientId?.substring(0, 4) + "...",
    });

    // First, try to get existing installations
    try {
      console.log("Checking installations...");
      const baseUrl = Constants.expoConfig?.extra?.isProduction
        ? "https://todo.ac"
        : "http://localhost:3000";

      const response = await fetch(`${baseUrl}/api/github-app-auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const error = await response.json();
        console.log("Error checking installations:", error);
        // Continue with installation flow
      } else {
        const data = await response.json();
        console.log("Found installations response:", data);
        const { installations } = data;
        console.log("Found installations:", installations);

        if (installations && installations.length > 0) {
          const installation = installations[0];
          console.log("Found existing installation:", installation);

          // For existing installations, just use it directly
          this.installationId = installation.id;
          await AsyncStorage.setItem("installationId", String(installation.id));
          console.log("Stored installation ID, proceeding to refresh token...");
          return this.refreshToken();
        }
      }

      console.log(
        "No existing installation found, proceeding with installation flow..."
      );
    } catch (error) {
      console.error("Error checking installations:", error);
      // Continue with installation flow on error
    }

    // If no installation found, proceed with installation flow
    const baseUrl = Constants.expoConfig?.extra?.isProduction
      ? "https://todo.ac"
      : Platform.OS === "web"
      ? "http://localhost:19006"
      : "http://localhost:8081";

    const redirectUri = Platform.select({
      web: `${baseUrl}/github-callback`,
      default: makeRedirectUri({
        scheme: "todo-ac",
        path: "github-callback",
      }),
    });

    // Debug logs
    console.log("=== Installation Flow Debug Info ===");
    console.log("Redirect URI:", redirectUri);
    console.log("Platform:", Platform.OS);
    console.log("Base URL:", baseUrl);
    console.log("Is production:", Constants.expoConfig?.extra?.isProduction);
    console.log("App Name:", process.env.EXPO_PUBLIC_GITHUB_APP_NAME);

    // Convert dots to hyphens and ensure the app name is properly encoded for URLs
    const appName = (process.env.EXPO_PUBLIC_GITHUB_APP_NAME || "").replace(
      /\./g,
      "-"
    );
    const encodedAppName = encodeURIComponent(appName);
    const authUrl = `https://github.com/apps/${encodedAppName}/installations/new?state=setup&redirect_uri=${encodeURIComponent(
      redirectUri
    )}`;

    console.log("Converted App Name:", appName);
    console.log("Auth URL:", authUrl);
    console.log("========================");

    const result = await WebBrowser.openAuthSessionAsync(authUrl, redirectUri);
    console.log("Auth result:", result);

    if (result.type === "success") {
      try {
        console.log(
          "Installation successful, checking for new installation..."
        );
        // Check installations again
        const baseUrl = Constants.expoConfig?.extra?.isProduction
          ? "https://todo.ac"
          : "http://localhost:3000";

        const newResponse = await fetch(`${baseUrl}/api/github-app-auth`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!newResponse.ok) {
          throw new Error("Failed to get installations after auth");
        }

        const { installations: newInstallations } = await newResponse.json();
        console.log("Installations after auth:", newInstallations);

        if (
          !newInstallations ||
          !Array.isArray(newInstallations) ||
          newInstallations.length === 0
        ) {
          throw new Error(
            "No installation found. Please try installing the app again."
          );
        }

        const installation = newInstallations[0];
        this.installationId = installation.id;
        await AsyncStorage.setItem("installationId", String(installation.id));
        console.log("Installation complete, proceeding to refresh token...");

        return this.refreshToken();
      } catch (error) {
        console.error("Error getting installation:", error);
        throw new Error("Failed to complete installation. Please try again.");
      }
    } else if (result.type === "dismiss") {
      throw new Error("Installation was cancelled. Please try again.");
    } else {
      console.error("Unexpected auth result:", result);
      throw new Error("An unexpected error occurred. Please try again.");
    }
  }

  private async refreshToken(): Promise<{
    token: string;
    installationId: number;
  }> {
    if (!this.installationId) {
      throw new Error("GitHub App not initialized");
    }

    const baseUrl = Constants.expoConfig?.extra?.isProduction
      ? "https://todo.ac"
      : "http://localhost:3000";

    const response = await fetch(`${baseUrl}/api/github-app-auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        installationId: this.installationId,
      }),
    });

    if (!response.ok) {
      const error = await response.json();
      console.error("Token refresh failed:", {
        status: response.status,
        statusText: response.statusText,
        error,
      });
      throw new Error(error.error || "Failed to get installation token");
    }

    const data = await response.json();
    console.log("Token refresh response:", data);

    if (!data.token) {
      throw new Error("No token received from server");
    }

    // Get the username from environment variables or installation data
    let username = process.env.EXPO_PUBLIC_ALLOWED_USER;
    console.log("Environment variables:", {
      EXPO_PUBLIC_ALLOWED_USER: process.env.EXPO_PUBLIC_ALLOWED_USER,
      EXPO_PUBLIC_GITHUB_APP_ID: process.env.EXPO_PUBLIC_GITHUB_APP_ID,
      EXPO_PUBLIC_GITHUB_APP_NAME: process.env.EXPO_PUBLIC_GITHUB_APP_NAME,
    });

    // If no username in env vars, try to get it from the installation data
    if (!username && data.installation?.account?.login) {
      username = data.installation.account.login;
      console.log("Using username from installation data:", username);
    }

    if (!username) {
      throw new Error("No allowed user configured");
    }

    // Initialize GitSync with the token and username
    initGitSync(data.token, username, "todo-data");

    return { token: data.token, installationId: this.installationId };
  }
}

export const githubAppService = new GitHubAppService();
