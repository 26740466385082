import React from "react";
import {
  Modal,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  Platform,
} from "react-native";

export interface ConflictData {
  path: string;
  local: {
    content: any;
    timestamp: string;
  };
  remote: {
    content: any;
    timestamp: string;
  };
  onResolve?: (useLocal: boolean) => void;
  onCancel?: () => void;
}

interface Props {
  conflict: ConflictData;
  onResolve: (useLocal: boolean) => void;
  onCancel: () => void;
  visible: boolean;
}

export function ConflictDialog({
  conflict,
  onResolve,
  onCancel,
  visible,
}: Props) {
  const formatContent = (content: any) => {
    try {
      return JSON.stringify(content, null, 2);
    } catch {
      return String(content);
    }
  };

  return (
    <Modal
      visible={visible}
      transparent
      animationType="fade"
      onRequestClose={onCancel}
    >
      <View style={styles.overlay}>
        <View style={styles.dialog}>
          <Text style={styles.title}>Conflict Detected</Text>
          <Text style={styles.subtitle}>
            This item was modified in multiple places:
          </Text>

          <ScrollView style={styles.optionsContainer}>
            <View style={styles.option}>
              <Text style={styles.timestamp}>
                Local version ({conflict.local.timestamp})
              </Text>
              <Text style={styles.content}>
                {formatContent(conflict.local.content)}
              </Text>
              <TouchableOpacity
                style={styles.button}
                onPress={() => onResolve(true)}
              >
                <Text style={styles.buttonText}>Keep Local Version</Text>
              </TouchableOpacity>
            </View>

            <View style={[styles.option, styles.optionDivider]}>
              <Text style={styles.timestamp}>
                Remote version ({conflict.remote.timestamp})
              </Text>
              <Text style={styles.content}>
                {formatContent(conflict.remote.content)}
              </Text>
              <TouchableOpacity
                style={styles.button}
                onPress={() => onResolve(false)}
              >
                <Text style={styles.buttonText}>Keep Remote Version</Text>
              </TouchableOpacity>
            </View>
          </ScrollView>

          <TouchableOpacity style={styles.cancelButton} onPress={onCancel}>
            <Text style={styles.cancelButtonText}>Cancel</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  dialog: {
    backgroundColor: "#1a1a1a",
    borderRadius: 12,
    padding: 20,
    width: "100%",
    maxWidth: 500,
    maxHeight: "80%",
  },
  title: {
    color: "#fff",
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 8,
  },
  subtitle: {
    color: "#666",
    fontSize: 16,
    marginBottom: 20,
  },
  optionsContainer: {
    maxHeight: 400,
  },
  option: {
    backgroundColor: "#333",
    borderRadius: 8,
    padding: 15,
    marginBottom: 15,
  },
  optionDivider: {
    borderTopWidth: 1,
    borderTopColor: "#444",
    paddingTop: 15,
  },
  timestamp: {
    color: "#666",
    fontSize: 14,
    marginBottom: 8,
  },
  content: {
    color: "#fff",
    fontSize: 16,
    marginBottom: 15,
    fontFamily: Platform.OS === "ios" ? "Menlo" : "monospace",
  },
  button: {
    backgroundColor: "#2ea44f",
    padding: 12,
    borderRadius: 6,
    alignItems: "center",
  },
  buttonText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
  cancelButton: {
    marginTop: 15,
    padding: 12,
    borderRadius: 6,
    alignItems: "center",
    backgroundColor: "#333",
  },
  cancelButtonText: {
    color: "#666",
    fontSize: 16,
    fontWeight: "bold",
  },
});
